/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Global functions
  function get_window_width(){
    var overflow_x = $('body').css('overflow-x');
    var overflow_y = $('body').css('overflow-y');

    $('body').css('overflow', 'hidden');

    var window_width = $(window).width();

    $('body').css({ 'overflowX': overflow_x, 'overflowY': overflow_y });

    return window_width;
  }

  var window_height = $(window).height();
  var window_width = get_window_width();

  function definite_resize(){
    var definite_resize = false;

    if( current_window_size !== $(window).width() ){
      definite_resize = true;
    }

    current_window_size = $(window).width();

    return definite_resize;
  }

  function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');

    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
          c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
      }
    }
    return "";
  }


  // Theme specific functions
  var HMQ = {
    fade_in_initial_hidden: function() {
      $('.initial_hidden').each(function(i) {
        var delay = (i + 1) * 800;

        $(this).delay(delay).css('opacity', '1');
      });
    },
    // set_heights: function() {

    // },
  };


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $(window).load(function() {
          HMQ.fade_in_initial_hidden();
        });

        // Menu toggle
        $('#toggle-menu').click(function(){
          $(this).toggleClass('open');
          $('.nav-section').toggleClass('menu_open');
          $('#menu_overlay').toggleClass('open');
          $('.title').toggleClass('open');
        });

        // Search field
        $('#s, .mob_search').click(function() {
          $(this).parent().addClass('show');
        });

        // $('#searchform').(function() {
        //   $('#searchsubmit').removeClass('arrow_show');
        // });

        // Close menu if clicked outside
        $(document).mouseup(function(e){
          var menu = $('#menu_overlay, #toggle-menu');

          if( !menu.is(e.target) && menu.has(e.target).length !== 1 ){
            $('#toggle-menu').removeClass('open');
            $('body').removeClass('menu_open');

            $('#menu_overlay').removeClass('open');
            $('.title').removeClass('open');
          }
        });

        // Smoking Quiz Modal - How much does smoking cost?
        $('#buttonSmoking').click(function(){
          var total = 0;

          // $.each($('.mySelectSmoking'),function() {
          $('.mySelectSmoking').each(function(index, el) {
            total += parseInt( $(this).val() );

            totalHours = total;
            perDay5 = 2.48; perWeek5 = 17.36; perMonth5 = 69.44; per6Month5 = 451.36; perYear5 = 902.72; per10Year5 = 9027.20; 
            perDay10 = 4.96; perWeek10 = 34.72; perMonth10 = 138.88; per6Month10 = 902.72; perYear10 = 1805.44; per10Year10 = 18054.40; 
            perDay20 = 9.91; perWeek20 = 69.37; perMonth20 = 277.48; per6Month20 = 1803.62; perYear20 = 3607.24; per10Year20 = 36072.40; 
            perDay30 = 14.86; perWeek30 = 104.02; perMonth30 = 416.08; per6Month30 = 2704.52; perYear30 = 5409.04; per10Year30 = 54090.40;
            perDay40 = 19.82; perWeek40 = 138.74; perMonth40 = 554.96; per6Month40 = 3607.24; perYear40 = 7214.48; per10Year40 = 72144.80;
            
            // console.log(totalHours);

            if(totalHours === 5){
              $("#sum").text('If you smoke 5 cigarettes per day you could save the below...');
              $('.per_day').text('1 day £'+perDay5);
              $('.per_week').text('1 week £'+perWeek5);
              $('.per_month').text('1 month £'+perMonth5);
              $('.per_six_months').text('every 6 months £'+per6Month5);
              $('.per_year').text('1 year £'+perYear5);
              $('.per_ten_years').text('10 years £'+per10Year5);
            }
            
            if(totalHours === 10){
              $("#sum").text('If you smoke 10 cigarettes per day you could save the below...');
              $('.per_day').text('1 day £'+perDay10);
              $('.per_week').text('1 week £'+perWeek10);
              $('.per_month').text('1 month £'+perMonth10);
              $('.per_six_months').text('every 6 months £'+per6Month10);
              $('.per_year').text('1 year £'+perYear10);
              $('.per_ten_years').text('10 years £'+per10Year10);
            }
            
            if(totalHours === 20){
              $("#sum").text('If you smoke 20 cigarettes per day you could save the below...');
              $('.per_day').text('1 day £'+perDay20);
              $('.per_week').text('1 week £'+perWeek20);
              $('.per_month').text('1 month £'+perMonth20);
              $('.per_six_months').text('every 6 months £'+per6Month20);
              $('.per_year').text('1 year £'+perYear20);
              $('.per_ten_years').text('10 years £'+per10Year20);
            }
            
            if(totalHours === 30){
              $("#sum").text('If you smoke 30 cigarettes per day you could save the below...');
              $('.per_day').text('1 day £'+perDay30);
              $('.per_week').text('1 week £'+perWeek30);
              $('.per_month').text('1 month £'+perMonth30);
              $('.per_six_months').text('every 6 months £'+per6Month30);
              $('.per_year').text('1 year £'+perYear30);
              $('.per_ten_years').text('10 years £'+per10Year30);
            }
            
            if(totalHours === 40){
              $("#sum").text('If you smoke 40 cigarettes per day you could save the below...');
              $('.per_day').text('1 day £'+perDay40);
              $('.per_week').text('1 week £'+perWeek40);
              $('.per_month').text('1 month £'+perMonth40);
              $('.per_six_months').text('every 6 months £'+per6Month40);
              $('.per_year').text('1 year £'+perYear40);
              $('.per_ten_years').text('10 years £'+per10Year40);
            }

          });
        });


        $('#buttonSmokingWelsh').click(function(){
          var totalWelsh = 0;

          // $.each($(".mySelectSmoking") ,function() {
          $('.mySelectSmoking').each(function(index, el) {
            totalWelsh += parseInt($(this).val());
            totalHoursWelsh = totalWelsh;
            perWelshDay5 = 2.29; perWelshWeek5 = 16.00; perWelshMonth5 = 69.00; perWelsh6Month5 = 418.00; perWelshYear5 = 836.00; perWelsh10Year5 = 8359.00; 
            perWelshDay10 = 4.58; perWelshWeek10 = 32.00; perWelshMonth10 = 137.00; perWelsh6Month10 = 836.00; perWelshYear10 = 1672.00; perWelsh10Year10 = 16717.00; 
            perWelshDay20 = 9.16; perWelshWeek20 = 64.00; perWelshMonth20 = 275.00; perWelsh6Month20 = 1672.00; perWelshYear20 = 3343.00; perWelsh10Year20 = 33434.00; 
            perWelshDay30 = 13.74; perWelshWeek30 = 96.00; perWelshMonth30 = 412.00; perWelsh6Month30 = 2508.00; perWelshYear30 = 5015.00; perWelsh10Year30 = 50151.00;
            perWelshDay40 = 18.32; perWelshWeek40 = 128.00; perWelshMonth40 = 550.00; perWelsh6Month40 = 3344.00; perWelshYear40 = 6687.00; perWelsh10Year40 = 66868.00;
 
            console.log(totalHoursWelsh);
            
            if(totalHoursWelsh === 5){
              $('#sum').text('Os ydych chi’n ysmygu 5 sigarét y dydd fe allech chi arbed yr isod...');
              $('.per_day').text('1 diwrnod £'+perWelshDay5);
              $('.per_week').text('1 wythnos £'+perWelshWeek5);
              $('.per_month').text('1 mis £'+perWelshMonth5);
              $('.per_six_months').text('6 mis £'+perWelsh6Month5);
              $('.per_year').text('1 flwyddyn £'+perWelshYear5);
              $('.per_ten_years').text('10 mlynedd £'+perWelsh10Year5);
            }
            
            if(totalHoursWelsh === 10){
              $('#sum').text('Os ydych chi’n ysmygu 10 sigarét y dydd fe allech chi arbed yr isod...');
              $('.per_day').text('1 diwrnod £'+perWelshDay10);
              $('.per_week').text('1 wythnos £'+perWelshWeek10);
              $('.per_month').text('1 mis £'+perWelshMonth10);
              $('.per_six_months').text('6 mis £'+perWelsh6Month10);
              $('.per_year').text('1 flwyddyn £'+perWelshYear10);
              $('.per_ten_years').text('10 mlynedd £'+perWelsh10Year10);
            }
            
            if(totalHoursWelsh === 20){
              $('#sum').text('Os ydych chi’n ysmygu 20 sigarét y dydd fe allech chi arbed yr isod...');
              $('.per_day').text('1 diwrnod £'+perWelshDay20);
              $('.per_week').text('1 wythnos £'+perWelshWeek20);
              $('.per_month').text('1 mis £'+perWelshMonth20);
              $('.per_six_months').text('6 mis £'+perWelsh6Month20);
              $('.per_year').text('1 flwyddyn £'+perWelshYear20);
              $('.per_ten_years').text('10 mlynedd £'+perWelsh10Year20);
            }
            
            if(totalHoursWelsh === 30){
              $('#sum').text('Os ydych chi’n ysmygu 30 sigarét y dydd fe allech chi arbed yr isod...');
              $('.per_day').text('1 diwrnod £'+perWelshDay30);
              $('.per_week').text('1 wythnos £'+perWelshWeek30);
              $('.per_month').text('1 mis £'+perWelshMonth30);
              $('.per_six_months').text('6 mis £'+perWelsh6Month30);
              $('.per_year').text('1 flwyddyn £'+perWelshYear30);
              $('.per_ten_years').text('10 mlynedd £'+perWelsh10Year30);
            }
            
            if(totalHoursWelsh === 40){
              $('#sum').text('Os ydych chi’n ysmygu 40 sigarét y dydd fe allech chi arbed yr isod...');
              $('.per_day').text('1 diwrnod £'+perWelshDay40);
              $('.per_week').text('1 wythnos £'+perWelshWeek40);
              $('.per_month').text('1 mis £'+perWelshMonth40);
              $('.per_six_months').text('6 mis £'+perWelsh6Month40);
              $('.per_year').text('1 flwyddyn £'+perWelshYear40);
              $('.per_ten_years').text('10 mlynedd £'+perWelsh10Year40);
            }

          });
        });
        
        //Simple Quiz Modal 
        $('#resultsModal').hide();
        $('#button').click(function() { 
          var total = 0;

          // $.each($('.mySelect') ,function() {
          $('.mySelect').each(function(index, el) {
            total += parseInt($(this).val());
            totalHours = total;

            $("#sum").text('You Scored: '+ totalHours);
          });
          
          if (totalHours <=3){//if total is less than or equal to 3
            $('.0').show();
            $('.4').hide();
            $('.6').hide();
          }
          
          if (totalHours >=4){// if total is greater than or equal to 4
            $('.4').show();
            $('.0').hide();
            $('.6').hide();
          }
          
          if (totalHours >=6){//if total is greater than or equal to 6
            $('.6').show();
            $('.0').hide();
            $('.4').hide();
          }
            
        });//Ends on click of button


        // Video popup
        var video = $('.page-template-template-success .video');
        
        if( video.length ){
          video.click(function() {

            // var video_url = $(this).data('video');
            // var split_video_url = video_url.split('/').filter(function(v){ return v !== ''; });
            // var split_video_url = video_url.split('/');
            var video_id = $(this).data('video');
            var modal_width = false;

            if( window_width > 1200 ){
              modal_width = 1050;
            } else if( window_width > 991 && window_width <= 1200 ){
              modal_width = 800;
            } else if( window_width > 767 && window_width <= 991 ){
              modal_width = 600;
            } else {
              modal_width = 480;
            }

            var options = {
              id: video_id,
              width: modal_width,
              autoplay: true
            };

            var player_id = 'video_' + video_id;
            var player = new Vimeo.Player(player_id, options);

            var modal_id = $(this).data('modal');
            $('#' + modal_id).modal('show');

            // console.log(video_id);
            // console.log(player_id);
            // console.log(player);

            

            // $('#' + modal_id).on('shown.bs.modal', function (e) {
            //   setTimeout(function(){
            //     player.play();
            //   }, 750);


            // });

            // $('#' + modal_id).on('hide.bs.modal', function (e) {
            //   player.destroy();
            // });
            
          });
        }

      //-- Animations
        // If holder not empty
        if( $('#anim_0').length ){
          // Get src of animation file
          var anim_0_file = $('#anim_0').data('src');
          // Define animation data
          var anim_0_data = {
            container: $('#anim_0')[0], // ID of animation div
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: anim_0_file // Path to file
          };
          // Load animation
          var anim_0 = bodymovin.loadAnimation( anim_0_data );
          anim_0.play();
        }

        if( $('#anim_1').length ){
          // Get src of animation file
          var anim_1_file = $('#anim_1').data('src');
          // Define animation data
          var anim_1_file_data = {
            container: $('#anim_1')[0], // ID of animation div
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: anim_1_file // Path to file
          };
          // Load animation
          var anim_1 = bodymovin.loadAnimation( anim_1_file_data );
          anim_1.play();
        }

        if( $('#anim_2').length ){
          // Get src of animation file
          var anim_2_file = $('#anim_2').data('src');
          // Define animation data
          var anim_2_data = {
            container: $('#anim_2')[0], // ID of animation div
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: anim_2_file // Path to file
          };
          // Load animation
          var anim_2 = bodymovin.loadAnimation( anim_2_data );
          anim_2.play();
        }

        if( $('#anim_3').length ){
          // Get src of animation file
          var anim_3_file = $('#anim_3').data('src');
          // Define animation data
          var anim_3_data = {
            container: $('#anim_3')[0], // ID of animation div
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: anim_3_file // Path to file
          };
          // Load animation
          var anim_3 = bodymovin.loadAnimation( anim_3_data );
          anim_3.play();
        }

        if( $('#anim_4').length ){
          // Get src of animation file
          var anim_4_file = $('#anim_4').data('src');
          // Define animation data
          var anim_4_data = {
            container: $('#anim_4')[0], // ID of animation div
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: anim_4_file // Path to file
          };
          // Load animation
          var anim_4 = bodymovin.loadAnimation( anim_4_data );
          anim_4.play();
        }

        if( $('#anim_5').length ){
          // Get src of animation file
          var anim_5_file = $('#anim_5').data('src');
          // Define animation data
          var anim_5_data = {
            container: $('#anim_5')[0], // ID of animation div
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: anim_5_file // Path to file
          };
          // Load animation
          var anim_5 = bodymovin.loadAnimation( anim_5_data );
          anim_5.play();
        }

        // Scroll Down
       $("#scroll").click(function() {
          $('html, body').animate({
            scrollTop: $("#home-links").offset().top
          }, 1500);
        });

       $("#down_0").click(function() {
          $('html, body').animate({
            scrollTop: $("#scroll_1").offset().top
          }, 1500);
        });

       $("#down_1").click(function() {
          $('html, body').animate({
            scrollTop: $("#scroll_2").offset().top
          }, 1500);
        });

       $("#down_2").click(function() {
          $('html, body').animate({
            scrollTop: $("#scroll_3").offset().top
          }, 1500);
        });

        $("#down_3").click(function() {
          $('html, body').animate({
            scrollTop: $("#scroll_4").offset().top
          }, 1500);
        });



        if( $('body').hasClass('cy') ){



        }













       // Home Popup video
       // $('#video-play').click(function() {
       //    // var video_url = $(this).data('video');
       //    // var split_video_url = video_url.split('/').filter(function(v){ return v !== ''; });
       //    // var video_id = split_video_url.pop();
       //    var video_id = $(this).data('video');
       //    var modal_width = false;

       //    console.log( video_id );

       //    if( window_width > 1200 ){
       //      modal_width = 1050;
       //    } else if( window_width > 991 && window_width <= 1200 ){
       //      modal_width = 800;
       //    } else if( window_width > 767 && window_width <= 991 ){
       //      modal_width = 600;
       //    } else {
       //      modal_width = 480;
       //    }

       //    var options2 = {
       //      id: video_id,
       //      width: modal_width,
       //      // autoplay: true
       //    };

       //    $('#video-modal-home').modal('show');

       //    player2 = new Vimeo.Player('video_one', options2);
       //    player2.play();

       //  });

       //  $('#video-modal-home').on('hidden.bs.modal', function (e) {
       //    player2.pause();
       //  });



       // Font Size Change

       $('.font_size[data-font-size]').on('click', function(){
          var bodysize = $(this).attr('data-font-size');
            $('.font_size[data-font-size]').removeClass('active');
            $(this).addClass('active');
            $('body, html').css('font-size', bodysize + "px");
            
            setCookie("bodyFont", bodysize);
        });

       var bodyFont = getCookie("bodyFont");
        if(bodyFont) {
          $('.font_size[data-font-size="'+ bodyFont +'"]').trigger('click');
        }

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
